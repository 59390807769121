@keyframes bgColor {
    0%{
        background: #0466c8;
    }
    20%{
        background: #0353a4;
    }
    40%{
        background: #023e7d;
    }
    60%{
        background: #002855;
    }
    80%{
        background: #001845;
    }
    100%{
        background: #001233;
    }
}

@import "../../../../style/configs/color.css";

.div-imagen {
    font-size: 12px;
    width: 90% !important;
    white-space: nowrap;
    cursor: pointer;

    img {
        margin-right: 5px;
        width: 25px;
    }

    .focus {
        border-radius: 25px;
        background-color: $color-btn-text-celeste;
        padding: 4px;
    }
}

.div-paginas{
    border: 1px;
    display: inline-block;
    width: 100%;
    margin: auto;
    text-align: left;

    .flecha-pagina {
        width: 30px;
        cursor: pointer;
    }

}

.div-regresar {
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    float: left;
    text-align: left;
    width: 40%;

    img {
        background-color: $color-btn-optional2-border;
        border-radius: 10px;
        padding: 7px;
        width: 35px;
        height: 32px;
        margin: auto;
        margin-right: 5px;
        cursor: pointer;
    }

    span{
        margin: auto;
        font-weight: bold;
        color: $secondary;
    }
}


.div-paginacion{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    width: 60%;
    text-align: right;
    float: right;

    span{
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media (max-width: 1100px) {

    .div-imagen {
        font-size: 9px;
    }

}

@media (max-width: 1000px) {

    .div-imagen {
        span{
            display: none;
        }
        img{
            width: 40px;
        }
    }

}



.select-containerS {
    background: none!important;
    cursor: pointer!important;
    position: absolute!important;
    right: 23px!important;;
    top: 33px!important;;
    border: 2px solid white;
    border-radius: 7px;
}

.select-containerS__control:hover, .select-containerS:hover {
    box-shadow: none!important;
}

.select-containerS__indicators {
    display: none!important;
    visibility: hidden!important;
}

.select-containerS__control {
    cursor: pointer!important;
    background: none!important;
    box-shadow: none!important;
    border-radius: 0 !important;
    border: 0 !important;
    min-height: 0px!important;
    width: 20px!important;
    height: 20px!important;
    padding: 0px!important;
    margin: 0px!important;
}

.select-containerS__value-container {
    padding: 0px!important;
    margin: 3px!important;
    background: url("../../../../assets/img/brush.png");
    background-size: contain;
    background-repeat: no-repeat;
}

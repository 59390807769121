@import "../../../../../style/configs/color";

.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}

.card-login p{
    font-size: 14px !important;
    line-height: 1.2
}

.card-login p {
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.card-login .foter, .card-login .buttons-box {
    width: 100%;
    justify-content: left !important;
}

.card-login span a {
    color: $color-btn-optional2;
}

.card-login a {
    color: $color-btn-text-celeste;
}

.card-login span {
    color: $color-btn-text-gris;
}

.bienvenida{
    color:white;
    font-weight: bold;
}
.buttons-box{
    display: flex;
    align-items: center;
    justify-content: center;
}



.card-login {
    border: 0;
    border-radius: 1rem;
    padding: 4px;
    text-align: center;
    color: $color-btn-text-celeste;
    position: absolute !important;
    bottom: 16px;
    left: 220px;
    width: 300px;
    font-size: 10px;
    z-index: 10 !important;

}


.card-login {
    bottom: -100%;
    transition: bottom 1s;
}
.card-login.animate {
    bottom: 16px;
}


.blue-gradient {
    position: absolute !important;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: url("../../../../../assets/img/login-fondo.png");
}

.blue-gradient-bg{
    background-image: url("../../../../../assets/img/pc-nube.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative !important;
    margin: auto !important;
    z-index: 1 !important;
}

.blue-gradient-bg.animate{
    background-image: url("../../../../../assets/img/pc-login-fondo.png");
}

@media (max-width:850px) {
    .blue-gradient-bg{
        background-image: url("../../../../../assets/img/movil-nube.gif");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: static !important;
        margin: auto !important;
        z-index: -11 !important;
    }

    .blue-gradient-bg.animate{
        background-image: url("../../../../../assets/img/movil-login-fondo.png");
    }

    .card-login {
        bottom: 5px;
        left: 1px;
        right: 1px;
        margin: 0 auto !important;
    }


    .card-login {
        bottom: -100%;
        transition: bottom 1s;
    }
    .card-login.animate {
        bottom: 10px;
    }

    .card-login button {
        width: 100%;
    }
}

@media (min-height:568px) {

    .card-login img {
        margin-top: 0px;
        height: 50px;
    }
}


.bt-Admin{
    background: #138086!important;
}

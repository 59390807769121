@import "../../../../../style/configs/color.css";

.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}

.card-login-register p{
    font-size: 14px !important;
    line-height: 1.2
}

.card-login-register p {
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.card-login-register .foter, .card-login-register .buttons-box {
    width: 100%;
    justify-content: left !important;
}

.card-login-register span a {
    color: $color-btn-optional2;
}

.card-login-register a {
    color: $color-btn-optional2;
}

.card-login-register span {
    color: $color-btn-text-gris;
}

.bienvenida{
    color:white;
    font-weight: bold;
}
.buttons-box{
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-imagen {
    width: 100% !important;
    text-align: center !important;
    padding: 0xp 0xp 0xp 0xp !important;
    margin: 0xp 0xp 0xp 0xp !important;
}

.card-login-register {
    border-radius: 1rem !important;
    color: $color-azul !important;
    width: 450px!important;
    font-size: 12px !important;
    margin: 0;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 25px 100px 25px 100px !important;
    display: inline-block!important;
    vertical-align: middle!important;
    text-align: left;
}



.card-login-register label {
    font-weight: bold !important;
    font-family: 'Titillium Web', sans-serif !important;
    margin-left: 15px;
    margin-bottom: 0.2em;
}

.card-login-register button {
    font-weight: bold !important;
    font-family: 'Titillium Web', sans-serif !important;
    margin: auto !important;
}

.form-group {
    margin-bottom: 0.2em!important;
}

.card-regresar {
    position: absolute;
    left: 20px;
    color: $color-btn-optional2;
}

.blue-gradient-bg-register {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #086484;
    position: relative!important;
}


.ul-tow {
    font-size: 10px;
    padding: 5px 3px 0px 15px;
    list-style-type: circle;
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
}

.ul-tow li {
    color: $color-btn-text-gris;
}

.ul-tow li.validado {
    color: $color-btn-text-celeste;
}


.readytogo {
    width: 350px !important;
    padding: 25px 50px  5px 50px!important;
    text-align: center !important;

    .text-negro {
        font-size: 18px;
        font-weight: bold;
        color: $color-btn-text-black;
    }

    .text-azul {
        font-size: 18px;
        color: $color-btn-text-celeste;
    }

}

.button-container-edit {
    cursor: pointer;
    background: #32a16b;
    width: 25px;
    height: 25px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-container-edit img { 
    height: 15px;
    width: 15px;
}
.button-container-delete {
    cursor: pointer;
    background: #c4183c;
    width: 25px;
    height: 25px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-container-delete img { 
    height: 15px;
    width: 15px;
}
@import "configs/color.css";

.rc-tabs-tab {
    background-color: #fff;
}
.rc-tabs-nav-wrap {
    background-color: #fff;
}
.rc-tabs-tab-active, .rc-tabs-tab-active {
    color: $color-btn-text-withe !important;
    background-color: $secondary;
    border-radius: 10px;
}
.rc-tabs-ink-bar {
    background-color: $color-btn-text-withe !important;
    display: none !important;
}

.rc-tabs-top .rc-tabs-tab {
    padding: 5px 10px 5px 10px;
    color: #000000;
    border-radius: 10px;
    margin: 5px;
    font-weight: bold;
}
.rc-tabs-tab:hover {
    color: #fff;
    background-color: $color-btn-secondary !important;
    box-shadow: $color-btn-primary-shadow;
}
.rc-tabs-top {
    border-bottom: none;
}

.rc-tabs-tabpane {
    overflow: hidden!important;
}

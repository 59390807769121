.button-container-edit {
    cursor: pointer;
    background: #3C8DBF!important;
    width: 25px;
    height: 25px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-container-edit img{
    height: 15px;
    width: 15px;
}
.button-container-delete {
    cursor: pointer;
    background: #9672CD!important;
    width: 25px;
    height: 25px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-container-delete img{
    height: 15px;
    width: 15px;
}
.img-icons {
    height: 15px;
    width: 15px;
}
